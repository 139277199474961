jQuery(document).ready(function($) {
    var $imageBg = $('[data-image-bg]');

    if(!$imageBg.length) return

    // Slideshow
    $imageBg.each(function() {
        var item = $(this);
        var image = $(item).data('image-bg');

        $(item).css({
            'backgroundImage': "url("+image+")"
        });
    });
});
jQuery(document).ready(function ($) {
    var $products_carousel = $('.home-products .box-carousel');

    if (!$products_carousel.length) return

    var product_thumbs = new Swiper('.products-thumbs', {
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        slidesPerView: 6,
        spaceBetween: 1,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: $('.box-thumbs .nav-next'),
            prevEl: $('.box-thumbs .nav-prev'),
        },
        breakpoints: {
            991: {
                slidesPerView: 4
            },
            767: {
                slidesPerView: 3
            },
            575: {
                slidesPerView: 2
            }
        }
    });

    var product_items = new Swiper('.products-items', {
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        thumbs: {
            swiper: product_thumbs
        }
    });
});
jQuery(document).ready(function($) {    
    var $fancyboxgallery = $('[data-fancybox^="group-"]');

    if(!$fancyboxgallery.length) return

    $.fancybox.defaults.hash = false;

    $('[data-fancybox^="group-]').each(function() {
        $(this).fancybox({
            loop: false
        });
    });
});
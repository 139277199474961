jQuery(document).ready(function($) {    
    var $fancyboxvideo = $('[data-fancyboxvideo]');

    if(!$fancyboxvideo.length) return

    $('[data-fancyboxvideo]').each(function() {
        $(this).fancybox({
            youtube : {
                showinfo : 0
            },
            vimeo : {
                color : 'f00'
            }
        });
    });
});